/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const ProjectPage = ({ data, location }) => {
  const { setBackground } = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;

  //

  useEffect(() => {
    setBackground(frontmatter.backgroundColor);
  }, []);

  //

  const sectionJSX = [];

  if (frontmatter?.sections?.[0]) {
    frontmatter.sections.forEach(section => {
      switch (section.type) {
        case `image`:
          sectionJSX.push(
            <section className="animation-appear-slow animation-delay-3 grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 mb-24">
              <Img
                className="w-full relative block"
                fluid={section.image.childImageSharp.fluid}
                alt=""
              />
            </section>
          );

          break;

        // TODO : soundcloud
        case `iframe`:
          sectionJSX.push(
            <section className="animation-appear-slow animation-delay-3 grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 mb-24">
              <div
                className="vimeo"
                dangerouslySetInnerHTML={{ __html: section.iframe }}
              ></div>
            </section>
          );

          break;

        case `text`:
          sectionJSX.push(
            <section className="animation-appear-slow animation-delay-3 grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 mb-24">
              <div
                className="project-page__content w-full relative"
                dangerouslySetInnerHTML={{ __html: section.text }}
              ></div>
            </section>
          );

          break;

        default:
          break;
      }
    });
  }

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="project-page w-full relative">
        <div className="grid pt-32 pb-24">
          <h1 className="animation-appear-slow grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 pt-2 xs:pt-4 pb-2 mb-6 xs:mb-0 f1">
            {frontmatter.title}
          </h1>

          {sectionJSX}
        </div>
      </Layout>
    </>
  );
};

export default ProjectPage;

export const query = graphql`
  query ProjectPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        backgroundColor
        sections {
          type
          text
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          iframe
        }
        seoDescription
        seoKeywords
      }
    }
  }
`;
